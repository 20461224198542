<!--
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
-->

<script>
    import { connected } from './chat/data.js';
    import App from './chat/App.svelte';
    import Connect from './chat/Connect.svelte';
</script>


<!--<h2>Chat</h2>-->

<section>
  {#if $connected}
    <App/>
  {:else}
    <Connect/>
  {/if}
</section>

