/*
 * Copyright (C) 2023 LANNOCC
 * @%@~LICENSE~@%@
 *
 * requires: forge
 */

export const VERSION = 2;

const ENCODING = 'utf-8';

const SIGN_PAD = forge.pss;
const SIGN_PAD_MGF = forge.mgf.mgf1;
const SIGN_PAD_MGF_ALGO = forge.md.sha512;
const SIGN_PAD_SALT_LENGTH = 420;
const SIGN_ALGO = forge.md.sha512;

// FIXME: We should probably not use the RSA encryption directly on messages,
// but instead use RSA to encrypt an AES key that is then used to encrypt the
// messages.


export async function load_keys(pem, pass) {
    const pubBegin = pem.indexOf('-----BEGIN PUBLIC KEY-----');
    let privkey;
    if (pem.startsWith('-----BEGIN ENCRYPTED ')) {
        if (!pass) return false;

        try {
            privkey = forge.pki.decryptRsaPrivateKey(
                pem.substring(0, pubBegin), pass);
            if (privkey == null) return false;
        }
        catch (e) {
            // We sometimes get an exception from forge for certain
            // incorrect passwords:
            //      "Cannot read private key.
            //       ASN.1 object does not contain an RSAPrivateKey."
            console.log(e);
            return false;
        }
    }
    else {
        privkey = forge.pki.privateKeyFromPem(
            pem.substring(0, pubBegin));
    }

    const pubkey = load_public_key(pem);
    return { priv: privkey, pub: pubkey };
}

export function load_public_key(pem) {
    const pubBegin = pem.indexOf('-----BEGIN PUBLIC KEY-----');
    return forge.pki.publicKeyFromPem(pem.substring(pubBegin));
}

export function sign(privkey, data) {
    const pss = SIGN_PAD.create({
        md: SIGN_ALGO.create(),
        mgf: SIGN_PAD_MGF.create(SIGN_PAD_MGF_ALGO.create()),
        saltLength: SIGN_PAD_SALT_LENGTH,
    });

    const md = SIGN_ALGO.create();
    md.update(data);
    const sig = privkey.sign(md, pss);
    return sig;
}

export function verify(pubkey, data, signature) {
    const pss = SIGN_PAD.create({
        md: SIGN_ALGO.create(),
        mgf: SIGN_PAD_MGF.create(SIGN_PAD_MGF_ALGO.create()),
        saltLength: SIGN_PAD_SALT_LENGTH,
    });

    let md = SIGN_ALGO.create();
    md.update(data);
    const verified = pubkey.verify(md.digest().getBytes(), signature, pss);
    return verified;
}

/*
export function encode(text) {
    return new TextEncoder(ENCODING).encode(text);
}

export function decode(bytes) {
    const len = bytes.length;
    const buf = new Uint8Array(len);
    for (let i=0; i<len; i++) {
        buf[i] = bytes.charCodeAt(i);
    }
    return new TextDecoder(ENCODING).decode(buf.buffer);
}
*/

export function encrypt(pubkey, data) {
    const encoded = forge.util.encodeUtf8(data);
    return pubkey.encrypt(encoded);
}

export function decrypt(privkey, data) {
    const decrypted = privkey.decrypt(data);
    return forge.util.decodeUtf8(decrypted);
}

