<!--
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
-->

<script>
    import { onMount/*, onDestroy*/ } from 'svelte';
    import About from './About.svelte';
    import Chat from './Chat.svelte';
    //import Debug from './Debug.svelte';

    export let active = Chat;

    let gws = '(unknown)';
    onMount(() => {
        gws = lank_status();
    });

    /*
    onDestroy(() => {
        lank_reset();
    });
    */
</script>


<header>
    <aside>
        <div>
            Gateway WebSocket Status:
            <div id=gws>{gws}</div>
        </div>
        <div>
            Users Online Here:
            <div id=users>(unknown)</div>
        </div>
    </aside>

    <nav>
        <button on:click={() => active = About}
            disabled={active==About}>About</button>
        <button on:click={() => active = Chat}
            disabled={active==Chat}>Chat</button>
        <!--
        <button on:click={() => active = Debug}
            disabled={active==Debug}>Debug</button>
        -->
    </nav>
</header>

<main>
    <svelte:component this={active}/>
</main>


<style>
    header aside {
        position: absolute;
        top: 1em;
        right: 1em;
        text-align: right;
        font-size: smaller;
    }

    #gws, #users {
        font-weight: bold;
    }

    header nav {
        text-align: center;
    }

    main {
        text-align: center;
        padding: 1em;
        /*
        max-width: 240px;
        */
        margin: 0 auto;
    }

    /*
    @media (min-width: 640px) {
        main {
            max-width: none;
        }
    }
    */
</style>

