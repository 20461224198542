<!--
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
-->

<script>
    import { remember_label } from '../stores.js';

    export let disabled;

    function toggle(event) {
        remember_label.update(b => !b);
    }
</script>


<label>
    <input type="checkbox" {disabled} checked={$remember_label} on:click={toggle}/>
    remember label
</label>

