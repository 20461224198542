/*
 * Copyright (C) 2023 LANNOCC
 * @%@~LICENSE~@%@
 */

import { writable, get } from 'svelte/store';

export const tabs = writable([ ]);
export const cur_tab = writable();

const tabid = writable(0);

export function tab_open(title, type, props, on_close) {
    const id = get(tabid) + 1;
    tabid.set(id);
    props['tabid'] = id;
    const tab = {
        id: id,
        title: title,
        type: type,
        props: props,
        on_close: on_close
    };
    //$tabs = [ ...$tabs, tab ];
    tabs.update(n => n.concat(tab));
    tab_active(id);
    return id;
}

function tab_get(id) {
    let tab;

    for (const checking of get(tabs)) {
        if (checking.id == id) {
            tab = checking;
            break;
        }
    }

    return tab;
}

export function tab_on_close(id, on_close) {
    const tab = tab_get(id);
    tab.on_close = on_close;
}

export function tab_active(id) {
    cur_tab.set(false);
    //alert(id);
    if (id) cur_tab.set(tab_get(id));
}

export function tab_close(id) {
    const tab = tab_get(id);
    //$tabs.splice($tabs.indexOf(tab), 1);
    const all = get(tabs);
    all.splice(all.indexOf(tab), 1);
    tabs.set(all);
    if (get(cur_tab) === tab) cur_tab.set(false);
    if (tab.on_close) tab.on_close(id);
}

export function tab_close_all() {
    cur_tab.set(false);
    tabs.set([ ]);
}

