/*
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
*/

import { writable } from 'svelte/store';

export const connected = writable(false);
export const signer = writable();
export const keys = writable();
export const histories = writable(new Map());
export const peers = writable(new Map());
export const registrations = writable(new Map());

