/*
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
*/

import { writable } from 'svelte/store';

export const remember_label = writable(
    localStorage.getItem('remember_label') == 'true'
);
remember_label.subscribe(value => {
    localStorage.setItem('remember_label', value ? 'true' : 'false');
});

export const saved_label = writable(
    localStorage.getItem('saved_label')
);
saved_label.subscribe(value => {
    localStorage.setItem('saved_label', value);
});

