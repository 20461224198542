<!--
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
-->

<script>
    import { onMount, tick } from 'svelte';
    import { remember_label, saved_label } from '../stores.js';
    import { connected, signer, keys } from './data.js';
    import { load_keys, sign } from './crypto.js';
    import RememberLabel from './RememberLabel.svelte';

    let label, label_in;
    let pass, pass_in;
    let err_in;
    if ($remember_label) label = $saved_label;

    onMount(() => {
        lank_on_message(on_lank);

        if (label) {
            pass_in.focus();
        }
        else {
            label = 'anonymous';
            label_in.focus();
        }
    });

    let connecting = false;

    async function sign_on() {
        saved_label.set($remember_label ? label : '');

        if (!label) {
            //alert('Label is required');
            err_in = label_in;
            err_in.focus();
            return;
        }

        connecting = true;
        await lank_get_registration(label);
    }

    async function on_lank(msg) {
        if (!connecting) {
            console.log('ignoring', msg);
            return;
        }

        try {
            if ('Registration' in msg) {
                const reg = msg.Registration;
                const pem = window.atob(reg.key_pair_pem);
                const keypair = await load_keys(pem, pass);

                if (keypair) {
                    $keys = keypair;
                    $signer = label;
                    await lank_on(label);
                }
                else {
                    err_in = pass_in;
                }
            }
            else if ('LabelNotFound' in msg) {
                err_in = label_in;
            }
            else if ('OnSign' in msg) {
                const data = window.atob(msg.OnSign);
                const sig = sign($keys.priv, data);
                await lank_on_signed(window.btoa(sig));
            }
            else if ('On:OK' in msg) {
                $connected = true;
            }
            else if ('On:NO' in msg) {
                console.error(msg);
                alert('FAILURE: ' + msg);
            }
            else {
                console.log('ignoring', msg);
            }
        }
        finally {
            if (err_in) {
                connecting = false;
                await tick();
                err_in.focus();
            }
        }
    }
</script>


<form on:submit|preventDefault={sign_on}>
    <ul>
        <li><input type=text placeholder=label
                   class={err_in===label_in ? 'error' : ''}
                   bind:this={label_in} bind:value={label}
                   on:change={() => err_in = err_in===label_in ? false : err_in}
                   disabled={connecting} /></li>
        <li><input type=password placeholder=password
                   class={err_in===pass_in ? 'error' : ''}
                   bind:this={pass_in} bind:value={pass}
                   on:focus={() => pass_in.select()}
                   on:change={() => err_in = err_in===pass_in ? false : err_in}
                   disabled={connecting} /></li>
        <li><RememberLabel disabled={connecting}/></li>
        <li><button type="submit" disabled={connecting}>Sign On</button></li>
    </ul>
</form>


<style>
    form ul, form li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    input.error {
        background-color: #FBB;
    }
</style>

