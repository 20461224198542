<!--
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
-->

<script>
    import { onMount, onDestroy } from 'svelte';
    import {
        connected, signer, keys, histories, peers, registrations
    } from './data.js';
    import {
        tab_open, tab_active, tab_close, tab_close_all, tabs, cur_tab
    } from './tabs.js';
    import { VERSION /*, decode*/ } from './crypto.js';
    import LabelInfo from './LabelInfo.svelte';
    import Peer from './Peer.svelte';

    let labels = [ ];
    onMount(async () => {
        lank_on_message(on_lank);
        await lank_get_labels();
    });

    //onDestroy(() => { tab_active(); });

    function handle_key(event) {
        if (event.keyCode == 27) { // ESC
            quit();
        }
    }

    function quit() {
        $connected = false;
        $signer = false;
        $keys = false;
        tab_close_all();
        $peers = new Map();
        $histories = new Map();
        $registrations = new Map();
        lank_reset('Quit');
    }

    async function info(label) {
        await lank_get_registration(label);
        tab_open('[info] ' + label, LabelInfo, { label: label }, (id) => {
            $histories.delete(label);
            //$histories = $histories;
        });
    }

    async function on_lank(msg) {
        if ('LabelsList' in msg) {
            labels = msg.LabelsList.labels;
        }
        else if ('History' in msg) {
            const history = msg.History;
            $histories.set(history.label, history.items);
            $histories = $histories;
        }
        else if ('Registration' in msg) {
            const reg = msg.Registration;
            //FIXME: warn about any registration key changes
            $registrations.set(reg.label, window.atob(reg.key_pair_pem));
            $registrations = $registrations;
        }
        else if ('Peer:OK' in msg) {
            const channel = msg['Peer:OK'];
            if (!channel.startsWith('U')) {
                console.error('unexpected channel', msg);
                return;
            }
            if (!$peers.has(channel)) $peers.set(channel, [ ]);
            const log = $peers.get(channel);
            log.push([ new Date(), false, 'Connected', false ]);
            $peers = $peers;
        }
        else if ('Peer:NO' in msg) {
            const channel = msg['Peer:NO'];
            if (!channel.startsWith('U')) {
                console.error('unexpected channel', msg);
                return;
            }
            if (!$peers.has(channel)) $peers.set(channel, [ ]);
            const log = $peers.get(channel);
            log.push([ new Date(), false, 'Failed', false ]);
            $peers = $peers;
        }
        else if ('Peer' in msg) {
            const channel = msg.Peer.channel;
            if (!channel.startsWith('S')) {
                console.error('unexpected channel', msg);
                return;
            }
            if (!$peers.has(channel)) $peers.set(channel, [ ]);
            const log = $peers.get(channel);
            log.push([ new Date(), false, 'Incoming connection', false ]);
            $peers = $peers;

            tab_open(msg.Peer.address, Peer, { remoteid: channel }, (id) => {
                //alert('close (remote-initiated)'); //FIXME
            });

        }
        else if ('Signed' in msg) {
            const version = msg.Signed.version;
            if (version != VERSION) {
                //FIXME: more sophisticated version handling
                console.error('version mismatch: '+version);
                return;
            }

            const channel = msg.Signed.channel;
            if (!$peers.has(channel)) $peers.set(channel, [ ]); //FIXME?
            const log = $peers.get(channel);

            const label = msg.Signed.label;
            const data = window.atob(msg.Signed.data);
            const sig = window.atob(msg.Signed.signature);

            log.push([ new Date(), label, data, sig ]);
            $peers = $peers;
        }
        else {
            console.log('ignoring', msg);
        }
    }
</script>


<!--<h2>Chat App</h2>-->

<svelte:window on:keydown={handle_key}/>

<form on:submit|preventDefault={() => false}>
    <div class=control><button type="button" on:click={quit}>Quit</button></div>
    <div class=labels>
        <ul>
          {#each labels as label}
            <li><button type="button" class=link on:click={() => info(label)}>
                {label}</button></li>
          {/each}
        </ul>
    </div>
    <div class=nav>
        <ul>
          {#each $tabs as tab}
            <li>
                <button type="button"
                        on:click={() => tab_close(tab.id)}>x</button>
                <button type="button" class="link"
                        on:click={() => tab_active(tab.id)}
                        disabled={tab === $cur_tab}>
                    {tab.title}
                </button>
            </li>
          {/each}
        </ul>
    </div>
    <div class=main>
      {#if $cur_tab}
        <svelte:component this={$cur_tab.type} {...$cur_tab.props}/>
      {/if}
    </div>
    <div class=activity>activity</div>
</form>


<style>
    form {
        display: grid;
        grid-gap: 9px;
        grid-template-columns: 33% 33% 33%;
        grid-template-areas:
            "control  nav      nav"
            "labels   main     main"
            "activity activity activity";
        color: #444;
    }

    form > div {
        background-color: #999;
        color: #FFF;
        border-radius: 5px;
        padding: 10px;
        text-align: left;
    }

    .control { grid-area: control; }
    .nav { grid-area: nav; }
    .labels { grid-area: labels; }
    .main { grid-area: main; }
    .activity { grid-area: activity; }

    .control {
        background-color: #FFF;
        padding: 0;
    }

    :global(html.theme) .control {
        background-color: #222;
    }

    .control, .nav {
        min-height: 3em;
        vertical-align: bottom; /* FIXME */
    }

    .control, .labels {
        text-align: right;
    }

    .activity {
        height: 4em;
    }

    .labels, .main {
        background-color: #444;
        min-height: 42vh;
    }

    .labels .link, .main .link {
        color: #6CF;
    }

    .nav ul, .nav li {
        list-style: none;
    }

    .nav ul {
        margin: 0;
        padding: 0;
        display: block;
    }

    .nav li {
        display: inline-block;
        background-color: #BBB;
        white-space: nowrap;
        padding: 0;
        padding-right: .2em;
        margin: .1em;
    }

    .nav li button {
        margin: 0;
    }

    .labels ul, .labels li {
        display: block;
        list-style: none;
    }
</style>

