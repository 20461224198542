<!--
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
-->

<h2>About LANK.im</h2>

<h3>Your password is never transmitted</h3>

<p><b>LANK.im</b> runs in your browser and communicates over websocket to a gateway running the <a href="https://github.com/lannocc/lank">LANK</a> protocol. The network of LANK nodes store the cryptographic keys and broadcast sign-on messages while all encryption and decryption occurs client-side.</p>


<style>
    p {
        text-align: left;
    }
</style>

