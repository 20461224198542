/*
 * Copyright (C) 2023 LANNOCC
 * @%@~LICENSE~@%@
 */

import LANK from './LANK.svelte';
//import Debug from './Debug.svelte';

const app = new LANK({
    target: document.getElementById('svapp'),
    props: {
        //active: Debug,
    }
});

export default app;

