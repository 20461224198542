<!--
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
-->

<script>
    import { tab_open } from './tabs.js';
    import { histories, registrations } from './data.js';
    import Peer from './Peer.svelte';

    export let label;

    function date_time(val) {
        const date = new Date(val / 100);
        /*
        alert(date);
        const hours = date.getHours();
        const minutes = '0' + date.getMinutes();
        const seconds = '0' + date.getSeconds();
        return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
         */
        return date;
    }

    async function connect(address) {
        const tabid = tab_open(address, Peer, {
            address: address, label: label
        }, (id) => {
            //alert('close (local-initiated)'); //FIXME
        });
        await lank_peer(address, tabid);
    }

    $: lank_get_sign_ons(label);
</script>


<h4>{label}</h4>

{#if $histories.has(label)}
  {#if $histories.get(label).length}
    <h5>Signed On</h5>
    <ul>
      {#each $histories.get(label) as event}
      {#if 'Signed' in event}
        <li>
            <button on:click={() => connect(event.Signed.address)}
                    >{event.Signed.address}</button>
            at {date_time(event.Signed.timestamp)}
        </li>
      {/if}
      {/each}
    </ul>
  {:else}
    Has never signed on.
  {/if}
{/if}

{#if $registrations.has(label)}
    <h5>Registration</h5>
    <pre>{$registrations.get(label)}</pre>
{/if}

