<!--
    Copyright (C) 2023 LANNOCC
    @%@~LICENSE~@%@
-->

<script>
    import { tick } from 'svelte';
    import { signer, peers, keys, registrations } from './data.js';
    import {
        VERSION, load_public_key, /*encode,*/ sign, verify, encrypt, decrypt
    } from './crypto.js';

    export let tabid;
    export let remoteid;
    export let address;
    export let label;

    let to_send_in;
    let to_send;
    let disabled = false;
    let load_label = true;

    function key_in(event) {
        if (event.key == '\n') {
            send();
        }
    }

    async function send() {
        disabled = true;
        const channel = remoteid ? remoteid : ('U'+tabid);
        $peers.get(channel).push([ new Date(), $signer, to_send ]);
        $peers = $peers;

        const pubkey = load_public_key($registrations.get(label));
        const encrypted = encrypt(pubkey, to_send);
        const signature = sign($keys.priv, encrypted);
        await lank_send(channel, VERSION, encrypted, signature, $signer);
        to_send = '';
        disabled = false;
        await tick();
        to_send_in.focus();
    }

    function check(entry) {
        const label = entry[1];
        const pubkey = load_public_key($registrations.get(label));
        const ok = verify(pubkey, entry[2], entry[3]);

        if (ok) {
            alert('OK: '+label+' sent the data which remains intact');
        }
        else {
            alert('FAIL: '+label+' did not send the data or it has changed');
        }
    }

    function open(entry) {
        entry[2] = decrypt($keys.priv, entry[2]);
        entry[3] = false;
        $peers = $peers;
    }

    $: if (remoteid && !label && $peers.get(remoteid).length > 1) {
        if (load_label) {
            const logs = $peers.get(remoteid);
            label = logs[logs.length-1][1];
            lank_get_registration(label);
            load_label = false;
        }
    }
</script>


<h4>Chat with {label}</h4>

{#if $peers.has(remoteid ? remoteid : ('U'+tabid))}
    <ul>
      {#each $peers.get(remoteid ? remoteid : ('U'+tabid)) as entry}
        <li>
            <span class=when>{entry[0]}</span>
            <span class=who>{entry[1]}</span>
            <span class=what>{entry[2]}</span>
          {#if entry[3]}
            <button type=button on:click={() => check(entry)}>verify</button>
            <button type=button on:click={() => open(entry)}>decrypt</button>
          {/if}
        </li>
      {/each}
    </ul>

  {#if label}
    <input type=text {disabled} bind:this={to_send_in} bind:value={to_send}
           on:keypress={key_in} autofocus/>
    <button type="submit" {disabled} on:click={send}>Send</button>
  {/if}
{/if}


<style>
    .when { color: blue; }
    .who { color: green }
    .what { color: orange; }
</style>

